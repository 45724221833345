export const data = [
  {
    id: 1,
    status: "Processing",
    customer_name: "John Smith",
    items: 2,
    total: 50,
    order_type: "Delivery",
    fulfillment_date: [2023, 3, 1],
    view: "1 button here",
  },
  {
    id: 2,
    status: "Completed",
    customer_name: "Jane Doe",
    items: 1,
    total: 25,
    order_type: "Pickup",
    fulfillment_date: [2023, 3, 2],
    view: "1 button here",
  },
  {
    id: 3,
    status: "Processing",
    customer_name: "Bob Johnson",
    items: 3,
    total: 70,
    order_type: "Delivery",
    fulfillment_date: [2023, 3, 3],
    view: "1 button here",
  },
  {
    id: 4,
    status: "Cancelled",
    customer_name: "Sara Lee",
    items: 4,
    total: 100,
    order_type: "Pickup",
    fulfillment_date: [2023, 3, 4],
    view: "1 button here",
  },
  {
    id: 5,
    status: "Completed",
    customer_name: "Tom Jones",
    items: 2,
    total: 50,
    order_type: "Delivery",
    fulfillment_date: [2023, 3, 5],
    view: "1 button here",
  },
  {
    id: 6,
    status: "Processing",
    customer_name: "Mary Johnson",
    items: 1,
    total: 30,
    order_type: "Pickup",
    fulfillment_date: [2023, 3, 6],
    view: "1 button here",
  },
  {
    id: 7,
    status: "Completed",
    customer_name: "Bob Johnson",
    items: 2,
    total: 45,
    order_type: "Delivery",
    fulfillment_date: [2023, 3, 7],
    view: "1 button here",
  },
  {
    id: 8,
    status: "Processing",
    customer_name: "Jill Smith",
    items: 3,
    total: 60,
    order_type: "Pickup",
    fulfillment_date: [2023, 3, 8],
    view: "1 button here",
  },
  {
    id: 9,
    status: "Completed",
    customer_name: "Sam Jones",
    items: 2,
    total: 55,
    order_type: "Delivery",
    fulfillment_date: [2023, 3, 9],
    view: "1 button here",
  },
];
