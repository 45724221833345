/* 
This is the component that renders the header of the application. 
The Header component contains:
    1. an image tag with a src attribute set to the logo imported image file. 
       It's recommended to use the import statement to load the image as a module, so that the image can be optimized and included in the application's build output. Additionally, using an import statement can make it easier to reference the image in other parts of the code, and can help catch errors if the image file path changes.
    2. the Navigation component is rendered, which renders the navigation bar of the application.
    3. the PageTitle component is rendered, which displays the page title. 
*/

import React from "react";
import PageTitle from "./PageTitle";
import Navigation from "./Navigation";
import logo from "./images/offbeat-donuts-logo.png";

function Header() {
  return (
    <header>
      <img id="logo" src={logo} alt="My Logo" />
      <Navigation />
      <PageTitle title="Blanchardstown Shopping Centre Store Orders" />
    </header>
  );
}

export default Header;
