export default function printSelectedRows(data, selected) {
  const selectedRows = data.filter((item) => selected.includes(item.id));
  const htmlContent = `
    <html>
      <head>
        <title>Selected Rows</title>
      </head>
      <body>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Status</th>
              <th>Customer Name</th>
              <th>Items</th>
              <th>Total</th>
              <th>Order Type</th>
              <th>Fulfillment Date</th>
            </tr>
          </thead>
          <tbody>
            ${selectedRows
              .map(
                (item) => `
              <tr>
                <td>${item.id}</td>
                <td>${item.status}</td>
                <td>${item.customer_name}</td>
                <td>${item.items}</td>
                <td>${item.total}</td>
                <td>${item.order_type}</td>
                <td>${new Date(
                  ...item.fulfillment_date
                ).toLocaleDateString()}</td>
              </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
      </body>
    </html>
  `;
  const newWindow = window.open("", "_blank", "height=500,width=700");
  newWindow.document.write(htmlContent);
  newWindow.document.close();
  newWindow.focus();
  newWindow.print();
}
