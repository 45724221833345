export function printRow(rowData) {
  const newWindow = window.open("", "_blank", "height=500,width=700");
  newWindow.document.write(`
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Status</th>
          <th>Customer Name</th>
          <th>Items</th>
          <th>Total</th>
          <th>Order Type</th>
          <th>Fulfillment Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>${rowData.id}</td>
          <td>${rowData.status}</td>
          <td>${rowData.customer_name}</td>
          <td>${rowData.items}</td>
          <td>${rowData.total}</td>
          <td>${rowData.order_type}</td>
          <td>${new Date(...rowData.fulfillment_date).toLocaleDateString()}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  `);
  newWindow.document.close();
  newWindow.focus();
  newWindow.print();
}
