/*
This is the main App component of the application 
The code imports the logo, CSS file, Header component and the OrdersList component.
The App component is a functional component that returns JSX code. 
The JSX code defines a container div with a class name of "App container". 
The container div contains the Header component and a main tag, which contains the OrdersList component.
The Header component is responsible for rendering the application header, 
The OrdersList component is responsible for rendering a list of orders.
Finally, the App component is exported as the default export of this module, which means that other modules can import this component using the import App from './App' statement.
*/

import logo from "./images/logo.svg";
import "./css/App.css";
import Header from "./Header";
import OrdersList from "./OrdersList";

function App() {
  return (
    <div className="App container">
      <Header />
      <main>
        <OrdersList />
      </main>
    </div>
  );
}

export default App;
