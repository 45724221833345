import React, { useState } from "react";
import { data } from "./OrdersData";
import printSelectedRows from "./PrintSelectedRows";
import { printRow } from "./PrintRow.js";
import SearchBox from "./SearchBox";

function OrdersList() {
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(data.map((item) => item.id));
    } else {
      setSelected([]);
    }
  };

  const handlePrintSelectedRows = () => {
    printSelectedRows(data, selected);
  };

  const handleSelect = (event, id) => {
    if (event.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((itemId) => itemId !== id));
    }
  };

  const handlePrintRow = (rowData) => {
    printRow(rowData);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="table_functions">
        <button onClick={handlePrintSelectedRows}>Print Selected</button>
        <SearchBox handleSearch={handleSearch} />
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selected.length === data.length}
                onChange={handleSelectAll}
              />
            </th>
            <th>ID</th>
            <th>Status</th>
            <th>Customer Name</th>
            <th>Items</th>
            <th>Total</th>
            <th>Order Type</th>
            <th>Fulfillment Date</th>
            <th>Actions</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selected.includes(item.id)}
                  onChange={(event) => handleSelect(event, item.id)}
                />
              </td>
              <td>{item.id}</td>
              <td>{item.status}</td>
              <td>{item.customer_name}</td>
              <td>{item.items}</td>
              <td>{item.total}</td>
              <td>{item.order_type}</td>
              <td>{new Date(...item.fulfillment_date).toLocaleDateString()}</td>
              <td>
                <button onClick={() => handlePrintRow(item)}>Print</button>
              </td>
              <td>{item.view}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={data.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

function Pagination({ itemsPerPage, totalItems, currentPage, setCurrentPage }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => (
    <li key={number}>
      <button onClick={() => setCurrentPage(number)}>{number}</button>
    </li>
  ));

  return <ul id="page-numbers">{renderPageNumbers}</ul>;
}

export default OrdersList;
